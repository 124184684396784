$(document).ready(function () {

    $("a.scrollto").click(function() {
        var elementClick = $(this).attr("href")
        var destination = $(elementClick).offset().top - 200;
        jQuery("html:not(:animated),body:not(:animated)").animate({
            scrollTop: destination
        }, 800);
        return false;
    });

    $(window).scroll(function(){
        let sticky = $('.header-fixed'),
            scroll = $(window).scrollTop();
        if (scroll >= 300) sticky.addClass('fixed');
        else sticky.removeClass('fixed');
    });

    let nav = $('.info-company__quest-text');
    let left = nav.offset().left;
    let $window = $(window);
    let h = nav.offset().top;
    let stop_line_fixed = h + ( $(".info-company__info").height() - 200 ) ;
    nav.css('left', left);
    $window.scroll(function(){
        if ( $window.scrollTop() >= stop_line_fixed ) {
            nav.removeClass('fixed');
            nav.addClass('fixed-bottom');
        } else if( $window.scrollTop() > h  ) {
            nav.addClass('fixed');
            nav.removeClass('fixed-bottom');
        }else{
            nav.removeClass('fixed');
            nav.removeClass('fixed-bottom');
        }
    });

    //модалка
    $('.popup-open-1').click(function() {
        $('.popup-1').addClass('visible-modal');
        $('body').addClass('body-hidden-scroll');
    });
    $('.popup-open-2').click(function() {
        $('.popup-2').addClass('visible-modal');
        $('body').addClass('body-hidden-scroll');
    });
    $('.popup__close').click(function() {
        $('.popup-1').removeClass('visible-modal');
        $('.popup-2').removeClass('visible-modal');
        $('body').removeClass('body-hidden-scroll');
    });


    // часы временные
    class Clock {
        constructor(id) {
            this.timezone = parseInt(document.getElementById(id).dataset.timezone);

            if (this.isDST(new Date())) {
                this.timezone += 1;
            }

            this.handSeconds = document.querySelector(`#${id} .hand.seconds`);
            this.handMinutes = document.querySelector(`#${id} .hand.minutes`);
            this.handHours = document.querySelector(`#${id} .hand.hours`);

            this.getTime();
            this.cycle();
        }

        isDST(now) {
            const jan = new Date(now.getFullYear(), 0, 1);
            const jul = new Date(now.getFullYear(), 6, 1);
            const dst = Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());

            return now.getTimezoneOffset() < dst;
        }

        draw(hours, minutes, seconds) {
            const drawSeconds = seconds / 60 * 360 + 90;
            const drawMinutes = minutes / 60 * 360 + 90;
            const drawHours = hours / 12 * 360 + 90;

            this.handSeconds.style.transform = `rotate(${drawSeconds}deg)`;
            this.handMinutes.style.transform = `rotate(${drawMinutes}deg)`;
            this.handHours.style.transform = `rotate(${drawHours}deg)`;

            // fix for animation bump on when clock hands hit zero
            if (drawSeconds === 444 || drawSeconds === 90) {
                this.handSeconds.style.transition = "all 0s ease 0s";
            } else {
                this.handSeconds.style.transition = "all 0.05s cubic-bezier(0, 0, 0.52, 2.51) 0s";
            }
        }

        getTime() {
            const now = new Date();

            const hours = now.getUTCHours() + this.timezone;
            const minutes = now.getUTCMinutes();
            const seconds = now.getUTCSeconds();

            this.draw(hours, minutes, seconds);
        }

        cycle() {
            setInterval(this.getTime.bind(this), 1000);
        }
    }

    new Clock('Montenegro');
    new Clock('Poland');
    new Clock('UAE');
    new Clock('Kazakhstan');


    var scene = document.getElementById('scene');
    var parallaxInstance = new Parallax(scene);
    var title_scene = document.getElementById('title-scene');
    var parallaxInstance = new Parallax(title_scene);


});

